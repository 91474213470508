import Cookies from "js-cookie";
import actions from "./actions";
import { DataService } from "../../config/dataService/dataService";

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post("/auth/login", values);
      console.log(response);
      console.log(values);
      if (response.data.throw) {
        console.log(response.data.throw.message);
        const err = response.data.throw.message;
        dispatch(loginErr(err));
      }
      if (!response.data.throw) {
        Cookies.set("access_token", response.data.access_token);
        // Get User from the axios call
        const registrationId = values.userNationalId;
        const user = await DataService.get(`/users/${registrationId}`, {});
        // console.log(user.data.Name);
        // console.log(user.data.Role);
        Cookies.set("Name", user.data.Name);
        Cookies.set("Role", user.data.Role);
        Cookies.set("logedIn", true);
        dispatch(loginSuccess(true));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post("/register", values);
      if (response.data.errors) {
        dispatch(loginErr("Registration failed!"));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove("logedIn");
      Cookies.remove("access_token");
      Cookies.remove("Name");
      Cookies.remove("Role");
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register };
