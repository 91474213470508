import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Demo1 = lazy(() => import('../../container/demo/Demo1'));
const Rainman = lazy(() => import('../../container/demo/Rainman'));

function DemoRoute() {
  return (
    <Routes>
      <Route path="demo1" element={<Demo1 />} />
      <Route path="Rainman" element={<Rainman />} />
    </Routes>
  );
}

export default DemoRoute;
