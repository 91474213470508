import { UilCircle } from "@iconscout/react-unicons";

import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import UilEllipsisV from "@iconscout/react-unicons/icons/uil-ellipsis-v";
import UilCreateDashboard from "@iconscout/react-unicons/icons/uil-create-dashboard";
import propTypes from "prop-types";

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = "/admin";

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/starter`}>
        {t("Mteja Meters")} {t("Home")}
      </NavLink>,
      "starter",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/starter`}>
          <UilCircle />
        </NavLink>
      )
    ),
    getItem(t("Clients"), "Clients Menu", !topMenu && <UilCreateDashboard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/client/registration`}>
          {t("Client Registration")}
        </NavLink>, // Menu Title
        null // Menu ID
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/client/list`}>
          {t("Client List")}
        </NavLink>, // Menu Title
        null // Menu ID
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/client/payment-invoice`}>
      //     {t("Payouts PDF")}
      //   </NavLink>, // Menu Title
      //   null // Menu ID
      // ),
    ]),
    getItem(t("Meter"), "Meter Menu", !topMenu && <UilCreateDashboard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/meter/registration`}>
          {t("Meter Registration")}
        </NavLink>, // Menu Title
        null // Menu ID
      ),
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/meter/client-meter-link`}
        >
          {t("Client Meter Link")}
        </NavLink>, // Menu Title
        null // Menu ID
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/meter/list`}>
          {t("Meter List")}
        </NavLink>, // Menu Title
        null // Menu ID
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/meter/payment-invoice`}>
      //     {t("Payouts PDF")}
      //   </NavLink>, // Menu Title
      //   null // Menu ID
      // ),
    ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? "home"
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
